<div class="welcome" *ngIf="hasCard">
  <div class="row">
    <div class="column" style="padding: 5%">
      <h2>Loading...</h2>
      <mat-spinner [diameter]="150"></mat-spinner>
    </div>
  </div>
</div>

<section *ngIf="!hasCard">
  <div class="welcome">
    <header class="welcome-header">
      <img
        alt="GM vehicle near campers"
        class="logos desktop-logo"
        src="assets/img/mastheads/login-welcome-masthead.png"
        title="Welcome GM Rewards cardmembers"
      />
      <img
        alt="GM vehicle near campers"
        class="logos mobile-logo"
        src="assets/img/mastheads/login-welcome-masthead-mobile.png"
        title="Welcome GM Rewards cardmembers"
      />
    </header>
    <div class="welcome-info col-6" style="height: 800px;">
      <app-info-container class="welcome-info-container" id="currentMembers">
        <ng-container header>Sign in to Your Account
        </ng-container>
        <ng-container main>
          <section class="gb-small-margin text-left">
            <ul>
              <li>View your Earnings statement</li>
              <li>See private offers</li>
              <li>Review Program Rules</li>
            </ul>
          </section>
          <section class="gb-small-margin text-left">
            <p class="account-notice">
              We’re simplifying how you sign in. Your previous username and
              password will no longer be valid, and you’ll need to use your
              email address as your new login. Click continue to sign in.
            </p>
          </section>
          <section class="gb-small-margin">
            <button
              (click)="continueToSignIn()"
              class="gb-primary-button green-button stat-button-link"
              data-dtm="current members"
            >
              Continue to Sign In
            </button>
          </section>
          <section class="gb-small-margin text-left">
            <p>
              <a
                href="http://www.marcus.com/us/en/login"
                target="_blank"
                class="text-black stat-text-link"
                data-dtm="current members"
                link-specific="marcus-with-policy-link"
                >Pay your bill</a
              >
              or manage your Credit Card account with
              <a
                href="http://www.marcus.com/us/en/login "
                target="_blank"
                class="text-black stat-text-link"
                data-dtm="current members"
                link-specific="marcus-with-policy-link"
                >Marcus</a
              >
              by Goldman Sachs<sup>&reg;</sup>.
            </p>
          </section>
        </ng-container>
      </app-info-container>
      <!-- <app-info-container class="welcome-info-container" id="newMembers"> -->
        <!-- <ng-container header>Become a Cardmember</ng-container>
        <ng-container main>
          <section class="text-right-card">
            Pick the card that's just right for you.
          </section>
          <section class="card-section d-inline-flex">
            <img src="assets/img/cards/card-gm-rewards.png" class="card-image" />
            <div class="ms-4">
              <h3>My GM Rewards&reg; Mastercard&reg;</h3>
              <p>
                Earn 4X points on all purchases<sup>1</sup> and 7X total points
                on all <span class="nowrap">GM purchases.<sup>2</sup></span>
              </p>
            </div>
          </section>
          <section class="card-section middle-card d-inline-flex">
            <img src="assets/img/cards/card-efc.png" class="card-image" />
            <div class="ms-4">
              <h3>GM Extended Family&trade; Mastercard&reg;</h3>
              <p>
                Combine 1% Credit Card Earnings on purchases with your GM
                employee or supplier discount (if eligible).<sup>3</sup>
              </p>
            </div>
          </section>
          <section class="card-section bottom-card d-inline-flex" style="margin-bottom: 1rem">
            <img src="assets/img/cards/card-business.png" class="card-image" />
            <div  class="ms-4">
              <h3>GM Business&trade; Mastercard&reg;</h3>
              <p>
                5% Earnings<sup>4</sup> on purchases of eligible GM parts,
                accessories and service at authorized GM dealers. 3%
                Earnings<sup>4</sup> on purchases at gas stations, restaurants
                and office supply stores. 1% Earnings<sup>4</sup> on all other
                purchases.
              </p>
            </div>
          </section>
          <section class="gb-large-margin no-bottom">
            <a
              href="https://experience.gm.com/rewards/card?evar25=gm_card_landing&chl=wb&schl=gmr"
              class="gb-primary-button green-button stat-button-link"
              data-dtm="become a member"
            >
              Learn More
            </a>
          </section>
        </ng-container> -->
      <!-- </app-info-container> -->
    </div>

    <div class="welcome-info-expander">
      <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(0)">
        <mat-expansion-panel-header data-dtm="intro" class="stat-expand-icon">
          <mat-panel-title>
            <mat-icon class="plus">add</mat-icon>
            <mat-icon class="minus">remove</mat-icon>
            Sign in to Your Account
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="expander--content">
          <section class="gb-small-margin text-left">
            <ul>
              <li>View your Earnings statement</li>
              <li>See private offers</li>
              <li>Review Program Rules</li>
            </ul>
          </section>
          <section class="gb-medium-margin text-left">
            <p class="account-notice">
              We’re simplifying how you sign in. Your previous username and
              password will no longer be valid, and you’ll need to use your
              email address as your new login. Click continue to sign in.
            </p>
          </section>
          <section class="gb-medium-margin">
            <button
              (click)="continueToSignIn()"
              class="gb-primary-button green-button stat-button-link"
              data-dtm="current members"
            >
              Continue to Sign In
            </button>
          </section>
          <section class="gb-medium-margin text-left">
            <p>
              <a
                href="http://www.marcus.com/us/en/login"
                target="_blank"
                class="text-black stat-text-link"
                data-dtm="current members"
                >Pay your bill</a
              >
              or manage your credit card account with
              <a
                href="http://www.marcus.com/us/en/login "
                target="_blank"
                class="text-black stat-text-link"
                data-dtm="current members"
                >Marcus</a
              >
              by Goldman Sachs<sup>&reg;</sup>.
            </p>
          </section>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(0)">
        <!-- <mat-expansion-panel-header class="stat-expand-icon" data-dtm="intro">
          <mat-panel-title class="mobile-title">
            <mat-icon class="plus">add</mat-icon>
            <mat-icon class="minus">remove</mat-icon>
            Become a Cardmember
          </mat-panel-title>
        </mat-expansion-panel-header> -->

        <div class="expander--content">
          <section class="gb-small-margin text-right-card">
            Pick the card that's just right for you.
          </section>
          <section class="card-section-mobile d-inline-flex">
            <img src="assets/img/cards/card-gm-rewards.png" />
            <div class="ms-4">
              <h3>My GM Rewards Card&trade;</h3>
              <p>
                Earn 4X points on all purchases<sup>1</sup> and 7X total points
                on all GM purchases.
                <sup>2</sup>
              </p>
            </div>
          </section>
          <section class="card-section-mobile middle-card d-inline-flex">
            <img src="assets/img/cards/card-efc.png" />
            <div class="ms-4">
              <h3>GM Extended Family Card&trade;</h3>
              <p>
                Combine 1% credit card Earnings on purchases with your GM
                employee or supplier discount (if eligible).
                <sup>3</sup>
              </p>
            </div>
          </section>
          <section
            class="card-section-mobile no-bottom d-inline-flex">
            <img src="assets/img/cards/card-business.png" />
            <div class="ms-4">
              <h3>GM Business Card&trade;</h3>
              <p>
                5% Earnings on purchases of eligible GM parts, accessories and
                service at authorized GM dealers. 3% Earnings
                <sup>4</sup>
                on purchases at gas stations, restaurants and office supply
                stores. 1% Earnings on all other purchases.
                <sup>5</sup>
              </p>
            </div>
          </section>
          <section class="gb-large-margin no-bottom">
            <a
              href="https://experience.gm.com/rewards/card?evar25=gm_card_landing&chl=wb&schl=gmr"
              class="gb-primary-button green-button stat-button-link"
              data-dtm="become a member"
            >
              Learn More
            </a>
          </section>
        </div>
      </mat-expansion-panel>
    </div>
  </div>
</section>

